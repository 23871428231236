
import { defineComponent } from 'vue';
import ModalMiniBox from './ModalMiniBox.vue';
import { projectsModalInfo, projectsModalOpen } from '@/utils/projectsInfo';
import { logProjectButtonClick } from '@/utils/analytics';

export default defineComponent({
    components: {
        ModalMiniBox
    },
    data() {
        return {
            screen: "computer",
            info: projectsModalInfo,
            monitorWidth: "80%",
            monitorMaxWidth: "220px"
        }
    },
    methods: {
        logButtonClick(name: string) {
            logProjectButtonClick(name, this.info.title);
        },
        handleClose() {
            projectsModalOpen.value = false;
        },
        resize() {
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";
        },
        setMonitorWidth() {
            if (this.info.title == 'BlitzDraft') {
                if (this.screen == 'computer') {
                    this.monitorWidth = '70%';
                } else {
                    this.monitorWidth = '40% !important';
                    this.monitorMaxWidth = '140px';
                }
            } else if (this.info.title == 'GuessNBA') {
                if (this.screen == 'computer') {
                    this.monitorWidth = '60%';
                } else {
                    this.monitorWidth = '35% !important';
                    this.monitorMaxWidth = '120px';
                }
            } else if (this.info.title == 'Seekr') {
                if (this.screen == 'computer') {
                    this.monitorWidth = '55%';
                } else {
                    this.monitorWidth = '35% !important';
                    this.monitorMaxWidth = '80px';
                }
            } else if (this.info.title == 'PickupUNL' || this.info.title == 'Sure' || this.info.title == 'Blokus') {
                if (this.screen == 'computer') {
                    this.monitorWidth = '90%';
                } else {
                    this.monitorWidth = '75% !important';
                    this.monitorMaxWidth = '260px';
                }
            }
        }
    },
    mounted() {
        this.resize();
        this.setMonitorWidth();
        window.addEventListener('resize', this.resize);
    },
});
